$(function(){
    $('.toggle a').on('click', function(){
      $(this).closest('.col').toggleClass('expand');
  
      if($(this).closest('.col.expand').length != 0){
        $(this).text('Less -');
      }else{
        $(this).text('Read More +');
      }
      return false;
    });

    $('.util-mobile-menu').on('click', function(e){
        e.preventDefault();
        $('body').toggleClass('mobile-menu-active');
    });

    $('.navbar nav a').on('click', function(){
        if($('body').hasClass('mobile-menu-active')){
            $('body').removeClass('mobile-menu-active');
        }
    });
  
    $('nav li a').click(function() {
  
          var trigger = $(this);
          //var triggerHeight = $('header .navbar').outerHeight();
      var triggerHeight = 0;
  
          if (location.pathname.replace(/^\//,'') == this.pathname.replace(/^\//,'')
              || location.hostname == this.hostname) {
  
              var target = $(this.hash);
              target = target.length ? target : $('[name=' + this.hash.slice(1) +']');
                 if (target.length) {
                      if($('nav li a').length == 0){
                       $('html,body').animate({
                           scrollTop: target.offset().top - triggerHeight
                      }, 400);
                   }else{
                       $('html,body').animate({
                           scrollTop: target.offset().top - triggerHeight
                      }, 400);
                   }
                  return false;
              }
          }
      });
  
  
  
          $('form#getstarted').submit(function(e){
              var self = $(this);
        var inst = $.remodal.lookup[$('[data-remodal-id=modal]').data('remodal')];
  
              $.ajax({
                  url:'//formspree.io/brad@barcommunications.com',
                  type:'POST',
                  data:self.serialize(),
                  dataType:'json',
                  success:function(){
                      console.log('submitted');
                      $('.alert',self).addClass('success').html('<p>Thanks for reaching out!  We\'ll be in touch.</p>');
                      self[0].reset();
  
            setTimeout(function(){
              inst.close();
            }, 1300);
                  }
              });
  
              e.preventDefault();
          });
  
  });
  